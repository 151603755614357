.detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
}

.detail-images-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
}

.detail-image {
    margin-bottom: 20px;
    border: 3px solid var(--gray-color);
    border-radius: 5px;
}

.detail-description-container {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
    line-height: 1.7;
}

.detail-title {
    font-size: 30px;
    text-decoration: none;
    color: var(--accent);
    font-weight: bold;
}

.link-text {
    text-decoration: none;
    color: var(--accent);
    font-size: 18px;
    margin-bottom: 15px;
    margin-left: 15px;
    font-weight: bold;
}

.link-text:hover {
    color: var(--light-accent);
}

.detail-description {
    color: var(--black-color);
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.detail-link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 68px;
    width: 110px;
    font-family: 'Trispace', sans-serif;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    margin-top: 10px;
    color: var(--gray-color);
}

.detail-icon {
    font-size: 50px;
}

.detail-icon-text {
    font-size: 12px;
    color: var(--light-accent);
    transform: translateY(20px);
    transition: transform 300ms ease-out;
}

.detail-link-container:hover .detail-icon-text{
    transform: translateY(0px);
}

.detail-link-container:hover .detail-icon {
    color: var(--light-accent);
}

.tech-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 340px;
    margin-bottom: 20px
}

.tech-title {
    font-size: 30px;
    color: var(--accent);
}

.tech-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    color: var(--black);
    height: 50px;
    width: 140px;
    border: 3px solid var(--gray-color);
    font-family: 'Trispace', sans-serif;
}

@media only screen and (max-width: 768px) {
    .detail-description-container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .detail-icon-text {
        transform: translateY(0px);
    }
}
