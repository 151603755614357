.seperator-container {
    height: 25px;
    width: 25px;
    transform: rotate(90deg);
    margin-bottom: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.small-chevron {
    height: 30px;
    width: 30px;
}
